<template>
    <div class="app">
        <Header/>

        <!-- Content -->
        <div class="container" id="our-works">
            <div class="row mt-5">
                <div class="col mt-5">
                    <h1 style="color: #FE9800; font-weight: bold;">Our Works</h1>
                    <hr style="border: 2px solid #0077E5;margin-left: -120px;width: 330px;">
                </div>
            </div>

            <div class="row mt-lg-5">
                <div class="col-12 col-md-4 col-lg-4 mt-3 mt-lg-5 align-self-top" v-for="project in projects" :key="project.projecttype_id">
                    <router-link :to="{ name: 'OurWorksDetail', params: { projectId: project.projecttype_id }}">
                        <div class="card" style="border:none;">
                            <img v-if="project.project_img" :src="'https://makutacomm-api.makutapro.id/storage/images/projects/'+project.project_img" class="img-fluid" alt="">
                            <div class="ratio ratio-16x9">
                            <iframe  v-if="project.Projectvideo" :src="project.Projectvideo" title="YouTube video" frameborder="0" width="100%" height="196px" allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>
                            </div>
                            <div class="card-body">
                                <ul class="list-group">
                                    <li>
                                        <p class="card-text">{{ project.project_name }}</p>
                                    </li>
                                    <li class="content-child " style="list-style-type: none;">
                                        {{project.type}}
                                    </li>
                                </ul>
                                <router-link :to="{ name: 'OurWorksDetail', params: { projectId: project.projecttype_id }}">
                                    <button type="button" class="btn btn-sm px-2 py-0 mt-3 btn-outline-primary"><small>View More</small></button>
                                </router-link>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <footer-page/>
    </div>
</template>

<script>
import FooterPage from '../components/FooterPage.vue'
import Header from '../components/Header.vue'
export default {
    name: "OurWorks",
    data(){
        return{
            projects:[]
        }
    },
    components : {
        FooterPage,
        Header
    },
    created() {
      this.$axios.get('https://makutacomm-adm.makutapro.id/api/ourWorks')
      .then((response) => {
       console.log(response)
        this.projects = response.data.data
       })
    },
}
        
</script>